import React, {Component} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {CenterLoading} from "../functions";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import {ArrowBack, Visibility, VisibilityOff} from "@mui/icons-material";
import {
    AppBar,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItem,
    OutlinedInput, Toolbar
} from "@mui/material";
import $ from "jquery";

export default class Account extends Component {

    componentDidMount() {}

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            error: false,
            loginPage: false,
            loginData: {
                email: "",
                password: "",
                username: "",
                bio: "",
                image: ""
            },
            loginTitle: 'الحساب',
            loginIsRegister: false,
            showPassword: false,
            loginLoading: false,
        };
        this.prepare();
    }
    checkTheme = () => {
        if (localStorage.getItem("theme") !== null) {
            return localStorage.getItem("theme").includes("true");
        } else {
            return true
        }
    }
    prepare = () => {
        setTimeout(() => {
            if (localStorage.getItem("logged") !== null) {
                window.main.setState({
                    randomAnime: "/"
                });
                setTimeout(() => {
                    document.getElementById('goRandom').click();
                },100);
            } else {

            }
        },100);
    }
    restartPage = () => {
        this.setState({
            loading: true,
            error: false
        });
        setTimeout(()=> {
            this.prepare();
        },500);
    }
    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword})
    };
    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    onPassword = (value) => {
        this.setState({
            loginData: {
                email: this.state.loginData.email,
                password: value.target.value,
                username: this.state.loginData.username,
                bio: this.state.loginData.bio,
                image: this.state.loginData.image
            },
        });
    };
    onBio = (value) => {
        this.setState({
            loginData: {
                email: this.state.loginData.email,
                password: this.state.loginData.password,
                username: this.state.loginData.username,
                bio: value.target.value,
                image: this.state.loginData.image
            },
        });
    };
    onImage = (value) => {
        this.setState({
            loginData: {
                email: this.state.loginData.email,
                password: this.state.loginData.password,
                username: this.state.loginData.username,
                bio: this.state.loginData.bio,
                image: ""
            },
        });
    };
    onUsername = (value) => {
        if (value.target.value.length < 20) {
            this.setState({
                loginData: {
                    email: this.state.loginData.email,
                    password: this.state.loginData.password,
                    username: value.target.value,
                    bio: this.state.loginData.bio,
                    image: this.state.loginData.image
                },
            });
        } else {
            // eslint-disable-next-line no-undef
            window.main.showMessage("يجب أن يكون أسم المستخدم أقل من 20 حرف","تنبيه","حسنا","",true);
        }
    };
    onEmail = (value) => {
        this.setState({
            loginData: {
                email: value.target.value,
                password: this.state.loginData.password,
                username: this.state.loginData.username,
                bio: this.state.loginData.bio,
                image: this.state.loginData.image
            },
        });
    };
    goLogin = () => {
        this.setState({
            loading:true
        });
        if (this.state.loginIsRegister) {
            //let params = new URLSearchParams();
            //params.append("data",btoa(unescape(encodeURIComponent(JSON.stringify(this.state.loginData)))));
            fetch('https://app.sanime.net/function/h10.php?page=register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: 'data=' + btoa(unescape(encodeURIComponent(JSON.stringify(this.state.loginData))))
            }).then((value) => {
                value.text().then((value) => {
                    const json = JSON.parse(value);
                    if (json.code === "1") {
                        this.setState({
                            loginLoading:false,
                            loading:false
                        });
                        // eslint-disable-next-line no-undef
                        window.main.showMessage(json.message,"تنبيه","حسنا","",true);
                        this.openReg();
                    } else {
                        window.main.showMessage(json.message,"تنبيه","حسنا","",true);
                        this.setState({
                            loginLoading:false,
                            loading:false
                        });
                    }
                }).catch((err) => {
                    this.setState({
                        loginLoading:false,
                        loading:false
                    });
                });
            }).catch((err) => {
                this.setState({
                    loginLoading:false,
                    loading:false
                });
            });
        } else {
            let userData = localStorage.getItem("loggedData");
            userData = JSON.parse(userData);
            let params = new URLSearchParams();
            params.append("email",this.state.loginData.email);
            params.append("password",this.state.loginData.password);
            fetch('https://app.sanime.net/function/h10.php?page=login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: params
            }).then(response => response.json())
            .then((json) => {
                    if (json.code === "1") {
                        this.setState({
                            loading: false,
                        });
                        localStorage.setItem("logged",true);
                        localStorage.setItem("loggedData",JSON.stringify(json.message));
                        window.location.href = "/";
                    } else {
                        // eslint-disable-next-line no-undef
                        window.main.showMessage(json.message,"تنبيه","حسنا","",true);
                        this.setState({
                            loading: false,
                        });
                    }
            }).catch((err) => {
                this.setState({
                    loading: false,
                });
            });
        }
    }
    openReg = () => {
        if (this.state.loginIsRegister) {
            this.setState({
                loginIsRegister: false
            });
        } else {
            this.setState({
                loginIsRegister: true
            });
        }
    }
    resetPassword = () => {
        this.setState({
            loading: true,
        });
        if (this.state.loginData.email.length > 5) {
            fetch('https://app.sanime.net/function/h10.php?page=reset&e='+this.state.loginData.email).then((value) => {
                value.text().then((value) => {
                    const json = JSON.parse(value);
                    if (json.code === "1") {
                        this.setState({
                            loading: false,
                        });
                        window.main.showMessage(json.message,"تنبيه","حسنا","",false);
                    } else {
                        window.main.showMessage(json.message,"تنبيه","حسنا","",false);
                        this.setState({
                            loading: false,
                        });
                    }
                }).catch((err) => {
                    window.main.showMessage("توجد مشكلة في الشبكة","تنبيه","حسنا","",false);
                    this.setState({
                        loading: false,
                    });
                });
            }).catch(() => {
                window.main.showMessage("توجد مشكلة في الشبكة","تنبيه","حسنا","",false);
                this.setState({
                    loading: false,
                });
            });
        } else {
            window.main.showMessage("قم بكتابة البريد اللأكتروني اولا","تنبيه","حسنا","",false);
            this.setState({
                loading: false,
            });
        }
    }
    render() {
        if (this.state.loading) {
            return <CenterLoading/>
        } else {
            return <Box style={{marginTop: '0px',marginBottom: '7px'}} height={'100%'}>
                <AppBar position="static" color="background" enableColorOnDark elevation={0}>
                    <Toolbar>
                        <IconButton
                            onClick={window.main.goLink.bind(this,"/")}
                            size="medium"
                            edge="start"
                            color="inherit"
                            aria-label="back"
                            sx={{ marginRight: "0px" }}
                        >
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1,textAlign: "center",fontSize: "17px",fontFamily: "Righteous",paddingRight: "9px" }} className={"oneLine"}>
                            {this.state.loginTitle}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Card style={{margin: "13px",backgroundColor:"transparent"}} elevation={0}>
                    <List>
                        {this.state.loginIsRegister ?  <ListItem style={{padding:"0px"}}>
                            <FormControl fullWidth focused color={"primary"} style={{margin: "5px"}} variant="outlined">
                                <InputLabel style={this.checkTheme() ? {color: 'white'} : {color: 'black'}} htmlFor="outlined-adornment-password">أسم المستخدم</InputLabel>
                                <OutlinedInput
                                    style={this.checkTheme() ? {color: 'white'} : {color: 'black'}}
                                    id="uername"
                                    type={'text'}
                                    value={this.state.loginData.username}
                                    onChange={this.onUsername}
                                    onClick={() => {
                                        setTimeout(() => {
                                            $("html").animate({ scrollTop: -10000 }, 500);
                                            $("body").animate({ scrollTop: -10000 }, 500);
                                        },200);
                                    }}
                                    label="أسم المستخدم"
                                />
                            </FormControl>
                        </ListItem> : <Box/>}
                        {this.state.loginIsRegister ?  <ListItem style={{padding:"0px"}}>
                            <FormControl fullWidth focused color={"primary"} style={{margin: "5px"}} variant="outlined">
                                <InputLabel style={this.checkTheme() ? {color: 'white'} : {color: 'black'}} htmlFor="outlined-adornment-password">الوصف</InputLabel>
                                <OutlinedInput
                                    style={this.checkTheme() ? {color: 'white'} : {color: 'black'}}
                                    id="bios"
                                    type={'text'}
                                    value={this.state.loginData.bio}
                                    onChange={this.onBio}
                                    onClick={() => {
                                        setTimeout(() => {
                                            $("html").animate({ scrollTop: -10000 }, 500);
                                            $("body").animate({ scrollTop: -10000 }, 500);
                                        },200);
                                    }}
                                    label="الوصف"
                                />
                            </FormControl>
                        </ListItem> : <Box/>}
                        <ListItem style={{padding:"0px"}}>
                            <FormControl fullWidth focused color={"primary"} style={{margin: "5px"}} variant="outlined">
                                <InputLabel style={this.checkTheme() ? {color: 'white'} : {color: 'black'}} htmlFor="outlined-adornment-password">البريد الألكتروني</InputLabel>
                                <OutlinedInput
                                    style={this.checkTheme() ? {color: 'white'} : {color: 'black'}}
                                    id="emails"
                                    type={'email'}
                                    value={this.state.loginData.email}
                                    onChange={this.onEmail}
                                    onClick={() => {
                                        setTimeout(() => {
                                            $("html").animate({ scrollTop: -10000 }, 500);
                                            $("body").animate({ scrollTop: -10000 }, 500);
                                        },200);
                                    }}
                                    label="البريد الألكتروني"
                                />
                            </FormControl>
                        </ListItem>
                        <ListItem style={{padding:"0px"}}>
                            <FormControl fullWidth focused color={"primary"} style={{margin: "5px"}} variant="outlined">
                                <InputLabel style={this.checkTheme() ? {color: 'white'} : {color: 'black'}} htmlFor="outlined-adornment-password">كلمة المرور</InputLabel>
                                <OutlinedInput
                                    style={this.checkTheme() ? {color: 'white'} : {color: 'black'}}
                                    id="passwords"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    value={this.state.loginData.password}
                                    onChange={this.onPassword}
                                    onClick={() => {
                                        setTimeout(() => {
                                            $("html").animate({ scrollTop: -10000 }, 500);
                                            $("body").animate({ scrollTop: -10000 }, 500);
                                        },200);
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="كلمة المرور"
                                />
                            </FormControl>
                        </ListItem>
                        {this.state.loginLoading ? <Container style={{margin: '5px',placeContent: 'center',textAlign: 'center'}}>
                            <CircularProgress style={{color: '#00b2ff'}}/>
                        </Container> : <ListItem style={{padding:"0px", placeContent: "center"}}>
                            <ListItem onClick={this.goLogin} button style={{width: '40%',margin: "7px", borderRadius: "5px",backgroundColor: "#2196f3", textAlign: "center",placeContent: "center"}}>
                                <Typography style={{fontFamily: "'Changa'",color: 'white'}}>
                                    {this.state.loginIsRegister ? 'أنشاء حساب' : 'تسجيل دخول'}
                                </Typography>
                            </ListItem>
                        </ListItem>}
                        <ListItem style={{padding:"0px", placeContent: "center"}}>
                            <ListItem onClick={this.openReg} button style={{margin: "7px", borderRadius: "5px",backgroundColor: "transparent", textAlign: "center",placeContent: "center"}}>
                                <Typography style={{fontFamily: "'Changa'",color: '#2196f3'}}>
                                    {this.state.loginIsRegister ? 'تسجيل دخول' : 'مستخدم جديد ؟ أنشاء حساب'}
                                </Typography>
                            </ListItem>
                        </ListItem>
                        {
                            !this.state.loginIsRegister ? <ListItem style={{padding:"0px", placeContent: "center"}}>
                                <ListItem onClick={this.resetPassword} button style={{margin: "7px", borderRadius: "5px",backgroundColor: "transparent", textAlign: "center",placeContent: "center"}}>
                                    <Typography style={{fontFamily: "'Changa'",color: '#2196f3'}}>
                                        نسيت كلمة المرور
                                    </Typography>
                                </ListItem>
                            </ListItem> : <Box/>
                        }
                    </List>
                </Card>
            </Box>
        }
    }
}
