import React, {Component} from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import {
    AppBar,
    Avatar, Button, Dialog, Divider, Fade, IconButton,
    ListItem, ListItemButton, ListItemIcon, ListItemText, Switch, Toolbar
} from "@mui/material";
import styled from "@emotion/styled";
import {ApiOutlined, ArrowBack, VerifiedOutlined} from "@mui/icons-material";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 55,
    height: 30,
    padding: 10,
    '& .MuiSwitch-switchBase': {
        margin: 2,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 25,
        height: 25,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

export default class Settings extends Component {

    componentDidMount() {}

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            sheetName: "",
            sheet: false,
            sheetLink: "",
            menuList: []
        };
        window.settings = this;
        this.prepare();
    }

    prepare = () => {
        fetch('https://app.sanime.net/function/h10.php?page=menu')
            .then(response => response.text())
            .then(data => this.setState({
                menuList: JSON.parse(data)
            }))
            .catch(error => console.error('Error:', error));
    }

    onCloseSheet = () => {
        this.setState({
            sheet: false
        });
    }

    openSheet = (name,link) => {
        this.setState({
            sheet: true,
            sheetName: name,
            sheetLink: link
        });
    }

    checkTheme = () => {
        if (localStorage.getItem("theme") !== null) {
            return localStorage.getItem("theme").includes("true");
        } else {
            return true
        }
    }
    update = () => {
        window.main.accountClear();
        this.setState({
            loading: true
        });
        setTimeout(() => {
            this.setState({
                loading: true
            });
        },300);
    }
    render() {
        return <Box style={{marginTop: '0px',marginBottom: '7px'}} height={'100%'}>
            <List>
                <ListItem sx={{placeContent: "center"}}>
                    <Avatar style={{width: "90px",height: "90px"}} className={this.checkTheme() ? "linedShadowD" : "linedShadowL"} alt="settingIcon" src={window.main.checkLogged() ? window.main.accountData().image  : window.main.isOtakuTime() ? "https://anime-ar.com/icon_1024.png" : "https://sanime.net/app_icon.png"} />
                </ListItem>
                <ListItem sx={{placeContent: "center"}}>
                    {
                        window.main.checkLogged() ? <Box>
                            <Button onClick={window.main.goLink.bind(this,"/update")} size={"small"} className={this.checkTheme() ? "linedShadowD" : "linedShadowL"} style={{fontFamily: "Changa",margin: "3px"}}>تعديل الحساب</Button>
                            <Button onClick={this.update} size={"small"} className={this.checkTheme() ? "linedShadowD" : "linedShadowL"} style={{fontFamily: "Changa",margin: "3px"}}>تسجيل خروج</Button>
                        </Box> : <Box>
                            <Button onClick={window.main.goLink.bind(this,"/account")} size={"small"} className={this.checkTheme() ? "linedShadowD" : "linedShadowL"} style={{fontFamily: "Changa",margin: "3px"}}>انشاء حساب</Button>
                            <Button onClick={window.main.goLink.bind(this,"/account")} size={"small"} className={this.checkTheme() ? "linedShadowD" : "linedShadowL"} style={{fontFamily: "Changa",margin: "3px"}}>تسجيل الدخول</Button>
                        </Box>
                    }
                </ListItem>
                <Divider sx={{borderRadius: "100px", opacity: "0.0", height: "10px"}} />
                <Divider sx={{borderRadius: "100px", opacity: "0.3"}} />
                <Box style={{borderRadius: "10px",marginTop: "10px"}} className={this.checkTheme() ? "linedShadowD" : "linedShadowL"}>
                    <ListItem sx={{padding: "1px 8px 1px 12px"}}>
                        <span style={{fontFamily: "Changa", flexShrink: "1",flexGrow: "1"}}>
                            تصميم التطبيق
                        </span>
                        <MaterialUISwitch sx={{ m: 1 }} checked={this.checkTheme()} onChange={(value) => {
                            window.main.switchTheme();
                        }} />
                    </ListItem>
                </Box>
                {this.state.menuList.length !== 0 ? this.state.menuList.map(r =>
                        <Box key={r.name} style={{borderRadius: "10px",marginTop: "10px"}} className={this.checkTheme() ? "linedShadowD" : "linedShadowL"}>
                            <ListItem sx={{padding: "1px 5px 1px 5px"}}>
                                <ListItemButton style={{borderRadius: "10px"}} onClick={() => {
                                    this.openSheet(r.name,r.link);
                                }}>
                                    <ListItemIcon>
                                        <ApiOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={r.name} />
                                </ListItemButton>
                            </ListItem>
                        </Box>
                    ) : <Box style={{display: "none"}}/>}
                {
                    window.main.checkLoggedAndPremium() ? <Box/> : !window.main.ios() ? <Box style={{borderRadius: "10px",marginTop: "10px"}} className={this.checkTheme() ? "linedShadowD" : "linedShadowL"}>
                        <ListItem sx={{padding: "1px 5px 1px 5px"}}>
                            <ListItemButton style={{borderRadius: "10px"}} onClick={() => {
                                if (window.main.checkLogged()) {
                                    let data = window.main.getUserData();
                                    window.main.openLink("https://sanime.net/subscribe/index.php?userId="+data["id"]);
                                } else {
                                    window.main.openLink("https://sanime.net/subscribe/");
                                }
                            }}>
                                <ListItemIcon>
                                    <VerifiedOutlined />
                                </ListItemIcon>
                                <ListItemText primary="أزالة الأعلانات" />
                            </ListItemButton>
                        </ListItem>
                    </Box> : <Box/>
                }
            </List>

            <Dialog
                fullScreen
                onClose={this.onCloseSheet}
                open={this.state.sheet}
                TransitionComponent={Transition}
            >
                <Box>
                    <Box style={{background: this.checkTheme() ? "#182128" : "#efeeee"}}>
                        <AppBar position="static" elevation={0} color={"background"} enableColorOnDark>
                            <Toolbar variant="dense" sx={{paddingLeft: "9px",paddingRight:"9px"}}>
                                <IconButton
                                    onClick={this.onCloseSheet}
                                    size="medium"
                                    edge="start"
                                    color="inherit"
                                    aria-label="back"
                                    sx={{ marginRight: "0px" }}
                                >
                                    <ArrowBack />
                                </IconButton>
                                <ListItem sx={{padding: "0px"}}>
                                    <ListItemText primary={this.state.sheetName} secondary={""} />
                                </ListItem>
                            </Toolbar>
                        </AppBar>
                    </Box>
                    <Box style={{background: this.checkTheme() ? "#182128" : "#efeeee", flexGrow: 1}} height={'94vh'}>
                        <iframe title={this.state.sheetName} style={{width: "100%", height: "99.5%"}} frameBorder={"false"} seamless src={this.state.sheetLink}/>
                    </Box>
                </Box>
            </Dialog>

        </Box>
    }
}
