import React, {Component} from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {CardContent, CircularProgress, List, ListItem} from "@mui/material";
import * as PropTypes from "prop-types";
import ListItemIcon from "@mui/material/ListItemIcon";
import Container from "@mui/material/Container";
import {CenterLoading} from "../functions";


ListItemIcon.propTypes = {children: PropTypes.node};
export default class AnimeDate extends Component {

    componentDidMount() {}
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            page: 1,
            data: [],
            error: false,
            menuFav: false,
            menuID: '',
            menuWatchData: {},
        };
        this.prepare();
    }
    checkTheme = () => {
        if (localStorage.getItem("theme") !== null) {
            return localStorage.getItem("theme").includes("true");
        } else {
            return true
        }
    }

    prepare = () => {
        fetch('https://app.sanime.net/function/h10.php?page=date').then((value) => {
            value.text().then((value) => {
                let JS = JSON.parse(value);
                this.setState({
                    data: JS,
                    loading: false
                });
            });
        }).catch(() => {
            this.setState({
                loading: false,
                error: true
            });
        });
    }
    restartPage = () => {
        this.setState({
            loading: true,
            error: false
        });
        setTimeout(()=> {
            this.prepare();
        },500);
    }

    render() {
        if (this.state.loading) {
            return ( <CenterLoading/> );
        } else {
            return <List>
                {
                    this.state.data.map((steps) => (
                        <div>
                            <ListItem style={{padding: '0px',width: "100%"}}>
                                <Card style={{width: "100%",margin: "6px"}}>
                                    <CardContent style={{padding: "3px"}}>
                                        <Typography style={{fontSize: '16px',textAlign: "center"}}>{steps.day}</Typography>
                                    </CardContent>
                                </Card>
                            </ListItem>
                            <ListItem style={{padding: '0px'}} key={steps.day}>
                                <Container disableGutters >
                                    <div className="scrollmenu" style={this.checkTheme() ? {backgroundColor: '#182128'} : {backgroundColor: '#efeeee'}}>
                                        {steps.list.map((step) => (
                                            <div key={step.name}>
                                                <Card style={{margin: "6px"}} sx={{ width: window.main.if360() ? 105 : 120 }} >
                                                    <CardActionArea>
                                                        <CardMedia
                                                            component="img"
                                                            height="175"
                                                            image={step.image}
                                                            alt={step.name}
                                                        />
                                                        <Divider sx={{height: 1.2}} color={'#00b2ff'}/>
                                                        <Stack style={{paddingLeft: '4px',paddingBottom: '3px',paddingTop: '1px'}} spacing={0}>
                                                            <Typography style={{textOverflow: "ellipsis",overflow: "hidden",fontSize: '14px',whiteSpace: "nowrap"}}>{step.name}</Typography>
                                                            <Typography style={{textAlign: 'left',fontSize: '14px'}}>{step.ep}</Typography>
                                                        </Stack>
                                                    </CardActionArea>
                                                </Card>
                                            </div>
                                        ))}
                                    </div>
                                </Container>
                            </ListItem>
                        </div>
                    ))
                }
            </List>
        }
    }
}
